function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Icon } from './icon.js';
export function choiceInput(type, placeholder) {
  var inputAttrs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var siblings = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  return m('.d-flex.flex-column.mt-3.ms-3.gap-2', [m('input.w-100', _objectSpread({
    type: type,
    placeholder: placeholder
  }, inputAttrs)), siblings]);
}
export function contactButtons(buttons) {
  return m('.d-grid.gap-3.mt-4', {
    style: 'grid-template-columns: 1fr auto 1fr'
  }, buttons.map(function (_ref) {
    var condition = _ref.condition,
      href = _ref.href,
      label = _ref.label,
      icon = _ref.icon;
    return condition && m('a.btn.btn-primary.d-flex.flex-row.flex-nowrap.gap-2.align-items-center', {
      href: href,
      rel: 'noreferrer',
      style: 'grid-column: 2;',
      target: '_blank'
    }, [icon && m(Icon, {
      icon: icon
    }), label]);
  }));
}
export function modifyClasses(previous, _ref2) {
  var _ref2$add = _ref2.add,
    add = _ref2$add === void 0 ? [] : _ref2$add,
    _ref2$remove = _ref2.remove,
    remove = _ref2$remove === void 0 ? [] : _ref2$remove;
  var cl = new Set();
  if (typeof previous === 'string') {
    previous.split(' ').forEach(function (cls) {
      return cl.add(cls);
    });
  } else if (Array.isArray(previous)) {
    previous.forEach(function (cls) {
      return cl.add(cls);
    });
  }
  add.forEach(function (cls) {
    return cls && cl.add(cls);
  });
  remove.forEach(function (cls) {
    return cls && cl["delete"](cls);
  });
  return Array.from(cl).join(' ');
}