import { apiRequest, ensureServiceWorkerReady } from './core.js';
import { Home, Imprint, Notification, Notify, PrivacyPolicy, Register, Update } from './routes/exports.js';
if (!window.translations) {
  await fetch('/translations').then(function (resp) {
    return resp.json();
  }).then(function (data) {
    window.translations = data;
  })["catch"](console.error);
}
var url = new URL(window.location.toString());
var code = url.searchParams.get('code');
ensureServiceWorkerReady(true).then();
if ((code === null || code === void 0 ? void 0 : code.length) === 36) {
  if (!/^#!\/impressum/.test(url.hash)) {
    await apiRequest('GET', '/api/tag/:code', {
      params: {
        code: code
      }
    }).then(function (resp) {
      window.codeResp = resp;
      var result = resp.result;
      if (result === 'unregistered') {
        m.route.set('/register');
      } else if (result === 'registered') {
        m.route.set('/notify');
      } else if (result === 'owned') {
        if (!/^#!\/notification/.test(url.hash)) {
          m.route.set('/update');
        }
      }
    });
  }
}
m.route(document.body, '/', {
  '/': Home,
  '/imprint': Imprint,
  '/notification': Notification,
  '/notify': Notify,
  '/privacy-policy': PrivacyPolicy,
  '/register': Register,
  '/update': Update
});